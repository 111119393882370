import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";

const getUser = async (userId) => {
    const docRef = doc(db, "users", userId);
    let userData = null;
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            userData = docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (err) {
        console.log(err);
    }

    return userData;
};

const getUsers = async (queries = {}) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "users"), ...queryArray, orderBy("date_created", "desc"));
    let fetchedUsers = [];
    try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedUsers.push({ ...doc.data(), id: doc.id });
        });
    } catch (err) {
        console.log(err);
    }
    return fetchedUsers;
};

export { getUser, getUsers };
