import React from "react";
import * as S from "./style";
import OfficeCallVisitsRow from "./OfficeCallVisitsRow";

const OfficeCallVisitsTable = ({ data }) => {
    return (
        <div>
            <S.TableHeadRow>
                <S.TableHead flex="0.5" $center>
                    Sl. No
                </S.TableHead>
                <S.TableHead>Date Created</S.TableHead>
                <S.TableHead>Store</S.TableHead>
                <S.TableHead>Person</S.TableHead>
                <S.TableHead>Comment</S.TableHead>
            </S.TableHeadRow>
            <S.TableBody>
                {data?.map((visit, idx) => (
                    <React.Fragment key={idx}>
                        <OfficeCallVisitsRow key={idx} visit={visit} idx={idx} />
                        <S.Divider></S.Divider>
                    </React.Fragment>
                ))}
            </S.TableBody>
        </div>
    );
};

export default OfficeCallVisitsTable;
