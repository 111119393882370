import { nanoid } from "nanoid";
import { db } from "../firebase";
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc } from "@firebase/firestore";

const addOfficeCall = async (payload, id = null) => {
    let office_call_id;
    if (id) {
        office_call_id = id;
    } else {
        office_call_id = nanoid();
    }
    const officeCallsCollection = collection(db, "office-calls");

    // Define the document reference
    const docRef = doc(officeCallsCollection, office_call_id);
    try {
        // Add or update the document
        await setDoc(docRef, payload);

        // Log a success message
    } catch (e) {
        console.log(e);
    }
};

const getOfficeCall = async (officeCallId) => {
    const docRef = doc(db, "office-calls", officeCallId);
    let officeCallData = null;
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            officeCallData = docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (err) {
        console.log(err);
    }

    return officeCallData;
};

const getOfficeCalls = async (queries = {}) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "office-calls"), ...queryArray, orderBy("date_created", "desc"));
    let fetchedOfficeCalls = [];
    try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedOfficeCalls.push({ ...doc.data(), id: doc.id });
        });
    } catch (err) {
        console.log(err);
    }
    return fetchedOfficeCalls;
};

export { addOfficeCall, getOfficeCall, getOfficeCalls };
