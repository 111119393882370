import React from "react";
import * as S from "./style";
import { Bar } from "react-chartjs-3";
import EmptyChart from "../../empty-chart-container/EmptyChart";

const chartData = {
    labels: ["a", "b", "c", "d", "e"],
    datasets: [
        {
            label: "store",
            data: [2, 4, 12, 4, 18],
            backgroundColor: "#FFA5CB",
            borderColor: "#d6025d",
            borderWidth: 2,
            barPercentage: 0.4,
        },
    ],
};
const BarChart = ({ data }) => {
    if (!data) {
        return <EmptyChart />;
    }
    return (
        <S.ChartContainer>
            <Bar
                data={data}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                stacked: false,
                            },
                        ],
                        yAxes: [
                            {
                                beginAtZero: true,
                                stacked: false,
                            },
                        ],
                    },
                    legend: {
                        display: false,
                        position: "bottom",
                        align: "start",
                        reverse: true,
                    },
                }}
            />
        </S.ChartContainer>
    );
};

export default BarChart;
