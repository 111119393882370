import React from "react";
import * as S from "./style";
import UsersTableRow from "./UsersTableRow";

const UsersTable = ({ data }) => {
    return (
        <div>
            <S.TableHeadRow>
                <S.TableHead flex="0.2" $center>
                    Sl. No
                </S.TableHead>
                <S.TableHead $center flex="0.5">
                    Area Codes
                </S.TableHead>
                <S.TableHead>Title</S.TableHead>
                <S.TableHead>Name</S.TableHead>
                <S.TableHead>Phone</S.TableHead>
            </S.TableHeadRow>
            <S.TableBody>
                {data?.map((user, idx) => (
                    <React.Fragment key={idx}>
                        <UsersTableRow key={idx} user={user} idx={idx} />
                        <S.Divider></S.Divider>
                    </React.Fragment>
                ))}
            </S.TableBody>
        </div>
    );
};

export default UsersTable;
