import styled from "@emotion/styled/macro";

export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    padding: 20px 30px;
`;

export const FlexContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
`;

export const SubFlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Value = styled.div`
    color: #1c1c1c;
    font-size: 14px;
`;

export const Title = styled.div`
    font-weight: 600;
`;
