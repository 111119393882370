import React, { useContext } from "react";
import * as S from "./style";
import { formatTime } from "../../../utils/format-date";
import { DataContext } from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";

const StoreTableRow = ({ store, idx }) => {
    const { data } = useContext(DataContext);
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/dashboard/stores/${store.id}`);
    };

    return (
        <S.TableRow key={idx} onClick={handleNavigate}>
            <S.TableData flex="0.5" $center>
                {idx + 1}.
            </S.TableData>
            <S.TableData>{formatTime(store?.date_created)}</S.TableData>
            <S.TableData $center>{store?.area_code}</S.TableData>
            <S.TableData>{store?.name}</S.TableData>
            <S.TableData>{store?.phone}</S.TableData>
            <S.TableData flex="3" mr="6px">
                {store?.address}
            </S.TableData>
            <S.TableData>{data.users[store?.user_id]?.name}</S.TableData>
        </S.TableRow>
    );
};

export default StoreTableRow;
