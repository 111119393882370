import React, { useContext } from "react";
import * as S from "./style";
import { formatTime } from "../../utils/format-date";
import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";

const FollowupRow = ({ visit, idx }) => {
    const { data } = useContext(DataContext);
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/dashboard/visits/${visit.id}`);
    };
    return (
        <S.TableRow key={idx} onClick={handleNavigate}>
            <S.TableData flex="0.5" $center>
                {idx + 1}.
            </S.TableData>
            <S.TableData>{formatTime(visit?.date_created)}</S.TableData>
            <S.TableData>{data.users[visit?.user_id]?.name}</S.TableData>
            <S.TableData>{data.stores[visit?.store_id]?.name}</S.TableData>
            <S.TableData flex="3">{data.stores[visit?.store_id]?.address}</S.TableData>
            <S.TableData>{formatTime(visit?.date)}</S.TableData>
        </S.TableRow>
    );
};

export default FollowupRow;
