import { useContext, createContext, useEffect, useState } from "react";
import { signOut, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { errorToast } from "../utils/toast";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);

    const userLoginWithEmail = (payload) => {
        let { email, password } = payload;
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/dashboard/home");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                errorToast("Invalid email or password");
            });
    };

    const logOut = () => {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            });
    };

    const handleGetUserdata = async (id) => {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setUserData(docSnap.data());
            if (!docSnap.data().permissions.dashboard) {
                errorToast("You do not have permission for dashboard!");
                logOut();
            }
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser === null) {
                setUser(currentUser);
                navigate("/sign-in");
            } else {
                setUser(currentUser);
                let user_id = currentUser.uid;
                handleGetUserdata(user_id);
                navigate("/dashboard/home");
            }
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return <AuthContext.Provider value={{ userLoginWithEmail, logOut, user, userData }}>{children}</AuthContext.Provider>;
};

export const UserAuth = () => {
    return useContext(AuthContext);
};
