import styled from "@emotion/styled/macro";

export const Container = styled.div`
    margin-top: 110px;
`;

export const FilterContainer = styled.div`
    padding: 0 12px 12px 12px;
    box-shadow: 0px 10px 10px -10px rgba(99, 99, 99, 0.2);
    display: flex;
    align-items: center;
    gap: 10px;
`;
export const GreyText = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #94a3b8;
`;
