import React from "react";
import * as S from "./style";
import StoreTableRow from "./StoreTableRow";

const StoreTable = ({ data }) => {
    return (
        <div>
            <S.TableHeadRow>
                <S.TableHead flex="0.5" $center>
                    Sl. No
                </S.TableHead>
                <S.TableHead>Date Created</S.TableHead>
                <S.TableHead $center>Area Code</S.TableHead>
                <S.TableHead>Store Name</S.TableHead>
                <S.TableHead>Phone</S.TableHead>
                <S.TableHead flex="3" mr="6px">
                    Address
                </S.TableHead>
                <S.TableHead>Added By</S.TableHead>
            </S.TableHeadRow>
            <S.TableBody>
                {data?.map((store, idx) => (
                    <React.Fragment key={idx}>
                        <StoreTableRow key={idx} store={store} idx={idx} />
                        <S.Divider></S.Divider>
                    </React.Fragment>
                ))}
            </S.TableBody>
        </div>
    );
};

export default StoreTable;
