import { createContext, useState } from "react";

export const LoaderContext = createContext();

const LoaderContextProvider = ({ children }) => {
    const [showLoader, setShowLoader] = useState(false);

    return <LoaderContext.Provider value={{ showLoader, setShowLoader }}>{children}</LoaderContext.Provider>;
};

export default LoaderContextProvider;
