import React, { useContext, useEffect, useRef, useState } from "react";
import * as S from "./reports.style";
import Spacer from "../../components/spacer/Spacer";
import { UserAuth } from "../../context/AuthContext";
import { where } from "firebase/firestore";
import DateRange from "../../components/date-range/DateRange";
import { MILLIS_IN_DAY, RANDOM_COLORS } from "../../constants/constants";
import CustomAutoComplete from "../../components/custom-auto-complete/CustomAutoComplete";
import { DataContext } from "../../context/DataContext";
import Header from "../../components/header/Header";
import { getPercentage } from "../../utils/global-functions";
import { getCounts, getMeetingAnalysis, getVisits } from "../../functions/visit.functions";
import LineChart from "../../components/charts/line-chart/LineChart";
import { getStoreAnalysis } from "../../functions/store.functions";
import PieChart from "../../components/charts/pie-chart/PieChart";
import FollowupVisistsTable from "../../components/followup-visits/FollowupVisitsTable";

const initialSummaryCounts = {
    visits: 0,
    prospects: 0,
    sales: 0,
    demo_given: 0,
};
const Reports = () => {
    const [queries, setQueries] = useState({});
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const { userData } = UserAuth();
    const { data } = useContext(DataContext);
    const [summaryCounts, _setSummaryCounts] = useState(initialSummaryCounts);
    const summaryCountRef = useRef(summaryCounts);
    const setSummaryCounts = (data) => {
        summaryCountRef.current = data;
        _setSummaryCounts(data);
    };
    const [visitSummaryChart, setVisitSummaryChart] = useState(null);
    const [prospectsSummaryChart, setProspectsSummaryChart] = useState(null);
    const [salesSummaryChart, setSalesSummaryChart] = useState(null);
    const [demoSummaryChart, setDemoSummaryChart] = useState(null);
    const [storeAnalysis, setStoreAnalysis] = useState(null);
    const [onlineOrderChart, setOnlineOrderChart] = useState(null);
    const [usingPOSChart, setUsingPOSChart] = useState(null);

    const [overallChart, setOverallChart] = useState(null);
    const [desktopChart, setDesktopChart] = useState(null);
    const [mobileChart, setMobileChart] = useState(null);
    const [demoChart, setDemoChart] = useState(null);
    const [followupChart, setFollowupChart] = useState(null);

    useEffect(() => {
        if (data?.area_code) {
            setQueries({ ...queries, area_code: where("area_code", "==", data.area_code) });
        }
    }, [data]);

    useEffect(() => {
        if (data.users !== null) {
            handleGetSummary();
            handleGetStoreAnalysis();
            handleGetMeetingAnalysis();
        }
    }, [queries, data]);

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date_created", ">=", start), end_date: where("date_created", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: null, end_date: null });
        }
    };
    const handleSelect = (value, key) => {
        if (value[key] !== null) {
            setQueries({ ...queries, [key]: where(key, "==", value[key]) });
        } else {
            setQueries({ ...queries, [key]: null });
        }
    };

    const handleSetLineChart = (count, label, color, cb) => {
        let lineChartData = {
            labels: Object.keys(count),
            datasets: [
                {
                    label: label,
                    data: Object.values(count),
                    borderColor: color ? color : "#FFA5CB",
                    fill: false,
                    tension: 0,
                },
            ],
        };
        cb(lineChartData);
    };

    const handleSetPieChart = (count, cb, color_id) => {
        if (!count) return;
        let labels = Object.keys(count);
        let visits = Object.values(count);
        let totalVisit = visits.reduce((a, b) => a + b, 0);

        let chartData = visits.map((v) => getPercentage(totalVisit, v));
        let colors = Object.values(count).map((item, idx) => RANDOM_COLORS[color_id + idx]);
        let pieChartData = {
            labels: labels,
            datasets: [
                {
                    data: chartData,
                    backgroundColor: colors,
                },
            ],
        };

        cb(pieChartData);
    };

    const handleGetSummary = async () => {
        handleGetVisitsSummary();
        handleGetProspectsSummary();
        handleGetSalesSummary();
        handleGetDemoSummary();
    };

    const handleGetVisitsSummary = async () => {
        let count = await getCounts(queries, "date", "date_created");
        if (!count) {
            setVisitSummaryChart(null);
            setSummaryCounts({ ...summaryCountRef.current, visits: 0 });
            return;
        }
        let totalVisits = Object.values(count).reduce((a, b) => a + b);
        setSummaryCounts({ ...summaryCountRef.current, visits: totalVisits });
        handleSetLineChart(count, "Visits", "#ff6384", setVisitSummaryChart);
    };
    const handleGetProspectsSummary = async () => {
        let count = await getCounts(queries, "date", "next_follow_up");
        if (!count) {
            setProspectsSummaryChart(null);
            setSummaryCounts({ ...summaryCountRef.current, prospects: 0 });
            return;
        }
        let totalProspects = Object.values(count).reduce((a, b) => a + b);
        setSummaryCounts({ ...summaryCountRef.current, prospects: totalProspects });
        handleSetLineChart(count, "Prospects", "#36a2eb", setProspectsSummaryChart);
    };
    const handleGetSalesSummary = async () => {
        let count = await getCounts(queries, "date", "is_paid");
        if (!count) {
            setSalesSummaryChart(null);
            setSummaryCounts({ ...summaryCountRef.current, sales: 0 });
            return;
        }
        let totalSales = Object.values(count).reduce((a, b) => a + b);
        setSummaryCounts({ ...summaryCountRef.current, sales: totalSales });
        handleSetLineChart(count, "Sales", "#ffce56", setSalesSummaryChart);
    };
    const handleGetDemoSummary = async () => {
        let count = await getCounts(queries, "date", "is_demo_given");
        if (!count) {
            setDemoSummaryChart(null);
            setSummaryCounts({ ...summaryCountRef.current, demo_given: 0 });
            return;
        }
        let totalDemoGiven = Object.values(count).reduce((a, b) => a + b);
        setSummaryCounts({ ...summaryCountRef.current, demo_given: totalDemoGiven });
        handleSetLineChart(count, "Demo Given", "#4bc0c0", setDemoSummaryChart);
    };

    const handleGetStoreAnalysis = async () => {
        let res = await getStoreAnalysis(queries);
        if (!res) {
            setStoreAnalysis(null);
            setOnlineOrderChart(null);
            setUsingPOSChart(null);
            return;
        }
        setStoreAnalysis(res);
        handleSetPieChart(res?.online_order_data, setOnlineOrderChart, 0);
        handleSetPieChart(res?.using_billing_software_data, setUsingPOSChart, 34);
    };

    const handleGetMeetingAnalysis = async () => {
        let res = await getMeetingAnalysis(queries);
        if (!res) {
            setOverallChart(null);
            setDesktopChart(null);
            setMobileChart(null);
            setDemoChart(null);
            setFollowupChart(null);
            return;
        }
        handleSetPieChart(res?.overall_likability, setOverallChart, 3);
        handleSetPieChart(res?.desktop_likability, setDesktopChart, 45);
        handleSetPieChart(res?.mobile_likability, setMobileChart, 52);
        handleSetPieChart(res?.demo_given, setDemoChart, 33);
        handleSetPieChart(res?.next_follow_up, setFollowupChart, 9);
    };

    return (
        <S.Wrapper>
            <Spacer mt="110px" />

            <S.FilterContainer>
                <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
                <CustomAutoComplete
                    data={data?.stores ? Object.values(data?.stores) : []}
                    label="Select Store"
                    onSelectChange={(value) => handleSelect(value, "store_id")}
                    type="store_id"
                />
                <CustomAutoComplete
                    data={data?.users ? Object.values(data?.users) : []}
                    label="Select User"
                    onSelectChange={(value) => handleSelect(value, "user_id")}
                    type="user_id"
                />
            </S.FilterContainer>

            <S.ChartContainer>
                <Header label="Summary" full={true} />
                <S.DataContainer>
                    <S.DataBox>
                        <S.Label>Total Visits :</S.Label>
                        <S.Value>{summaryCountRef.current.visits}</S.Value>
                    </S.DataBox>
                    <S.DataBox>
                        <S.Label>Total Prospects :</S.Label>
                        <S.Value>{summaryCountRef.current.prospects}</S.Value>
                    </S.DataBox>
                    <S.DataBox>
                        <S.Label>Total Sales :</S.Label>
                        <S.Value>{summaryCountRef.current.sales}</S.Value>
                    </S.DataBox>
                    <S.DataBox>
                        <S.Label>Total Demo Given :</S.Label>
                        <S.Value>{summaryCountRef.current.demo_given}</S.Value>
                    </S.DataBox>
                </S.DataContainer>
                <LineChart data={visitSummaryChart} />
                <S.Divider></S.Divider>
                <LineChart data={prospectsSummaryChart} />
                <S.Divider></S.Divider>
                <LineChart data={salesSummaryChart} />
                <S.Divider></S.Divider>
                <LineChart data={demoSummaryChart} />
            </S.ChartContainer>

            <S.ChartContainer>
                <Header label="Store Analysis" full={true} />
                <S.DataContainer>
                    <S.DataBox>
                        <S.Label>Avg. Square Feet :</S.Label>
                        <S.Value>{storeAnalysis?.avg_sq_feet ?? 0}ft.</S.Value>
                    </S.DataBox>
                    <S.DataBox>
                        <S.Label>Avg. No Of Employees :</S.Label>
                        <S.Value>{storeAnalysis?.avg_employee ?? 0}</S.Value>
                    </S.DataBox>
                    <S.DataBox>
                        <S.Label>Online Orders :</S.Label>
                        <S.Value>{storeAnalysis?.online_order_percentage ?? 0}%</S.Value>
                    </S.DataBox>
                    <S.DataBox>
                        <S.Label>Use Billing Software :</S.Label>
                        <S.Value>{storeAnalysis?.billing_software_percentage ?? 0}%</S.Value>
                    </S.DataBox>
                </S.DataContainer>
                <S.PieChartContainer>
                    <S.PieChartBox>
                        <PieChart data={onlineOrderChart} />
                        <S.ChartLabel>Online Orders</S.ChartLabel>
                    </S.PieChartBox>
                    <S.PieChartBox>
                        <PieChart data={usingPOSChart} />
                        <S.ChartLabel>Use Billing Software</S.ChartLabel>
                    </S.PieChartBox>
                </S.PieChartContainer>
            </S.ChartContainer>
            <S.ChartContainer>
                <Header label="Meeting Analysis" full={true} />
                <S.MeetingPieChartContainer>
                    <S.PieChartBox>
                        <PieChart data={overallChart} legend={true} />
                        <S.ChartLabel>Overall Likability</S.ChartLabel>
                    </S.PieChartBox>
                    <S.PieChartBox>
                        <PieChart data={desktopChart} legend={true} />
                        <S.ChartLabel>Desktop POS Likability</S.ChartLabel>
                    </S.PieChartBox>
                    <S.PieChartBox>
                        <PieChart data={mobileChart} legend={true} />
                        <S.ChartLabel>Mobile POS Likability</S.ChartLabel>
                    </S.PieChartBox>
                    <S.PieChartBox>
                        <PieChart data={demoChart} legend={true} />
                        <S.ChartLabel>Demo Given</S.ChartLabel>
                    </S.PieChartBox>
                    <S.PieChartBox>
                        <PieChart data={followupChart} legend={true} />
                        <S.ChartLabel>Next Follow Up</S.ChartLabel>
                    </S.PieChartBox>
                </S.MeetingPieChartContainer>
            </S.ChartContainer>
        </S.Wrapper>
    );
};

export default Reports;
