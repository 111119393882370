import React, { useContext, useEffect } from "react";
import { LoaderContext } from "../../context/LoaderContext";

const IndexPage = () => {
    const { setShowLoader } = useContext(LoaderContext);
    useEffect(() => {
        setShowLoader(true);
        return () => {
            setShowLoader(false);
        };
    }, []);

    return <div></div>;
};

export default IndexPage;
