import React, { useContext, useEffect, useState } from "react";
import * as S from "./stores.style";
import DateRange from "../../components/date-range/DateRange";
import { MILLIS_IN_DAY } from "../../constants/constants";
import { Box, TextField, Typography } from "@mui/material";
import { getStores } from "../../functions/store.functions";
import StoreTable from "../../components/stores/store-table/StoreTable";
import { orderBy, where } from "firebase/firestore";
import AreaCode from "../../components/area-code/AreaCode";
import { DataContext } from "../../context/DataContext";
import { UserAuth } from "../../context/AuthContext";
import CustomAutoComplete from "../../components/custom-auto-complete/CustomAutoComplete";
import Header from "../../components/header/Header";

const Stores = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [storesData, setStoresData] = useState(null);
    const [tempData, setTempData] = useState(null);
    const [queries, setQueries] = useState({});
    const { data } = useContext(DataContext);
    const { userData } = UserAuth();
    const [searchedText, setSearchedText] = useState("");

    useEffect(() => {
        handleGetStoresData();
    }, [queries]);

    useEffect(() => {
        handleAreaCodeChange();
    }, [data.area_code]);

    //TEMP
    useEffect(() => {
        if (data?.area_code) {
            setQueries({ ...queries, area_code: where("area_code", "==", data.area_code) });
        }
    }, [data]);

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date_created", ">=", start), end_date: where("date_created", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: null, end_date: null });
        }
    };

    const handleGetStoresData = async () => {
        let fetchedData = await getStores(queries);
        setTempData(fetchedData);
        if (searchedText) {
            handleSearchStore(fetchedData, searchedText);
        } else {
            setStoresData(fetchedData);
        }
    };

    const handleAreaCodeChange = () => {
        if (data.area_code) {
            setQueries({ ...queries, area_code: where("area_code", "==", data.area_code) });
        } else {
            setQueries({ ...queries, area_code: null });
        }
    };

    const handleSelect = (value, key) => {
        if (key === "user_id") {
            if (value[key] !== null) {
                setQueries({ ...queries, [key]: where(key, "==", value[key]) });
            } else {
                setQueries({ ...queries, [key]: null });
            }
        }
    };

    const handleSearchChange = (event) => {
        let { name, value } = event.target;

        setSearchedText(value);
        handleSearchStore(tempData, value);
        // if (value) {
        //     setQueries({
        //         ...queries,
        //         name_start: where("name", ">=", value),
        //         name_end: where("name", "<=", value + "\uf8ff"),
        //         name_order: orderBy("name"),
        //     });
        // } else {
        //     setQueries({
        //         ...queries,
        //         name_start: null,
        //         name_end: null,
        //         name_order: null,
        //     });
        // }

        // TODO:
        // name: or(
        //     // query as-is:
        //     and(where("name", ">=", value), where("name", "<=", value + "\uf8ff")),
        //     // capitalize first letter:
        //     and(
        //         where("name", ">=", value.charAt(0).toUpperCase() + value.slice(1)),
        //         where("name", "<=", value.charAt(0).toUpperCase() + value.slice(1) + "\uf8ff")
        //     ),
        //     // lowercase:
        //     and(where("name", ">=", value.toLowerCase()), where("name", "<=", value.toLowerCase() + "\uf8ff"))
        // ),
    };
    const handleSearchStore = (data, value) => {
        let s1 = data.filter((a) => a.name.toLowerCase() === value.toLowerCase());
        let s2 = data.filter((a) => a.name.toLowerCase().startsWith(value.toLowerCase()));
        let s3 = data.filter((a) => a.name.toLowerCase().includes(value.toLowerCase()));
        let final = [...s1, ...s2, ...s3];
        let finalStores = {};
        final.forEach((item) => (finalStores[item.id] = item));

        setStoresData(Object.values(finalStores).sort((a, b) => a.name - b.name));
    };
    return (
        <S.Container>
            <S.FilterContainer>
                <TextField label="Search Store" name="store" onChange={handleSearchChange} />
                <CustomAutoComplete
                    data={data?.users ? Object.values(data?.users) : []}
                    label="Select User"
                    onSelectChange={(value) => handleSelect(value, "user_id")}
                    type="user_id"
                />
                <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
            </S.FilterContainer>
            <div>
                <Box sx={{ m: "12px 0px" }}>
                    <Header label="Stores" />
                </Box>
                <div>
                    <StoreTable data={storesData} />
                </div>
            </div>
        </S.Container>
    );
};

export default Stores;
