import React from "react";
import * as S from "./style";

const EmptyChart = () => {
    return (
        <S.EmptyContainer>
            <S.EmptyText>No Data Present</S.EmptyText>
        </S.EmptyContainer>
    );
};

export default EmptyChart;
