import React, { useContext } from "react";
import HomeDropLogo from "../../assets/images/homedrop-logo.svg";
import { TailSpin } from "react-loader-spinner";
import * as S from "./style";
import { Modal } from "@mui/material";
import { LoaderContext } from "../../context/LoaderContext";

const Loader = () => {
    const { showLoader } = useContext(LoaderContext);
    return (
        <Modal open={showLoader} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <TailSpin
                    height="80"
                    width="80"
                    color="#ffde2c"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
                <S.Logo src={HomeDropLogo} alt="" />
            </S.Wrapper>
        </Modal>
    );
};

export default Loader;
