import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../utils/format-date";
import "./daterange.css";
import { Box, Typography } from "@mui/material";

const DateRange = ({ startDate, endDate, handleDateChange }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* <Typography >Salect Date Range</Typography> */}
            <ReactDatePicker
                selectsRange={true}
                startDate={Date.parse(calendarFormatDate(startDate))}
                endDate={Date.parse(calendarFormatDate(endDate))}
                onChange={(update) => {
                    handleDateChange(update);
                }}
                isClearable={startDate !== null && endDate !== null && true}
                dateFormat="dd/MM/yyyy"
                className="date-range"
                placeholderText="Select Time Period"
            />
        </Box>
    );
};

export default DateRange;
