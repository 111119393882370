export const NAV_LINKS = [
    {
        name: "Home",
        route: "home",
    },
    {
        name: "Stores",
        route: "stores",
    },
    {
        name: "Visits",
        route: "visits",
    },
    {
        name: "Users",
        route: "users",
    },
    {
        name: "Reports",
        route: "reports",
    },
    {
        name: "Upcoming Followups",
        route: "upcoming-followups",
    },
];
