import { Autocomplete, Box, TextField, createFilterOptions } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { getAreaCodes } from "../../functions/area-code.functions";
import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

const filter = createFilterOptions();
const AreaCode = () => {
    const [value, setValue] = useState(null);
    const [areaCodes, setAreaCodes] = useState([]);
    const { handleSetData } = useContext(DataContext);
    const { userData } = UserAuth();

    useEffect(() => {
        //TEMP
        // setValue(userData?.default_area_code);
        handleSetAreaCodes();

        //main function
        // handleGetAreaCodes();
    }, [userData]);
    const handleGetAreaCodes = async () => {
        let fetchedCodes = await getAreaCodes();
        setAreaCodes(fetchedCodes);
    };

    const handleSetAreaCodes = () => {
        setAreaCodes(userData?.area_code);
        setValue(userData?.default_area_code);
        handleSetData({ area_code: userData?.default_area_code });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#fff",
                p: 1,
                borderRadius: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: "10px",
                }}
            >
                <Autocomplete
                    // disabled
                    value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                            setValue(newValue);
                            handleSetData({ area_code: newValue });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue(newValue);
                        } else if (newValue) {
                            setValue(newValue);
                        } else {
                            setValue(null);
                            handleSetData({ area_code: null });
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.name);
                        if (inputValue !== "" && !isExisting) {
                            // filtered.push({
                            //     inputValue,
                            //     name: `Add New Store`,
                            // });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    disableClearable
                    handleHomeEndKeys
                    id="area-code"
                    options={areaCodes}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option;
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option}
                            {/* ({option.code}) */}
                        </li>
                    )}
                    sx={{ width: 250 }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Area Code" />}
                />
            </Box>
        </Box>
    );
};

export default AreaCode;
