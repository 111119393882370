import React, { useRef, useState } from "react";
import * as S from "./style";
import { Pie } from "react-chartjs-3";
import EmptyChart from "../../empty-chart-container/EmptyChart";

const chartData = {
    labels: ["Electricity", "Rent", "Food & Beverages"],
    datasets: [
        {
            data: [563, 50, 126],
            backgroundColor: ["#4318FF", "#ABCEFF", "#FFDE2C", "#57AEFE", "#9D1FFF", "#FB1FFF"],
        },
    ],
};
const PieChart = ({ data, legend = false }) => {
    const chartRef = useRef(null);
    const [hiddenIndex, setHiddenIndex] = useState(null);

    const handleToggle = (segmentIndex) => {
        const chart = chartRef.current.chartInstance;
        const meta = chart.getDatasetMeta(0);
        const segment = meta.data[segmentIndex];
        const hiddenSegments = meta.data.filter((d) => d.hidden).length;

        if (hiddenSegments === meta.data.length - 1 && !segment.hidden) {
            return; // Prevent hiding all segments
        }
        if (hiddenIndex === segment._index) {
            setHiddenIndex(null);
        } else {
            setHiddenIndex(segment._index);
        }
        segment.hidden = !segment.hidden;
        chart.update();
    };
    const getLegendHtml = () => {
        return chartData.labels.map((item, index) => {
            return (
                <S.LegendRow key={index} onClick={() => handleToggle(index)}>
                    <S.LegendBox color={chartData.datasets[0].backgroundColor[index]}></S.LegendBox>
                    <S.LegendTitle strike={index === hiddenIndex}>
                        {item}: ₹{chartData.datasets[0].data[index]}
                    </S.LegendTitle>
                </S.LegendRow>
            );
        });
    };

    if (!data) {
        return <EmptyChart />;
    }
    return (
        <S.ChartContainer>
            <Pie
                ref={chartRef}
                data={data}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    legend: {
                        display: legend,
                        position: "bottom",
                        align: "center",
                        labels: {
                            boxWidth: 24,
                            boxHeight: 24,
                        },
                        onClick: function (event, legendItem, legend) {
                            // console.log("event: ", event);
                            // console.log("legendItem: ", legendItem);
                            // console.log("legend: ", legend);
                        },
                    },
                    // plugins: {
                    tooltip: {
                        callbacks: {
                            title: function (tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index];
                                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                // return label + ": " + value + "%";
                                return `${label}: ${value}%`;
                            },
                        },
                    },
                    // },
                }}
            />
            {/* <S.LegendContainer>{getLegendHtml()}</S.LegendContainer> */}
        </S.ChartContainer>
    );
};

export default PieChart;
