import moment from "moment/moment";

export const formatTime = (unix) => {
    return moment(unix).format("lll");
};

export const calendarFormatDate = (time) => {
    return moment(time).format("MM/DD/YYYY");
};

export const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
};
