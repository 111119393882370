import React, { useContext, useEffect, useState } from "react";
import * as S from "./upcoming-followups.style";
import Spacer from "./../../components/spacer/Spacer";
import Header from "../../components/header/Header";
import { Box, Button } from "@mui/material";
import { getVisits } from "../../functions/visit.functions";
import { orderBy, where } from "firebase/firestore";
import { UserAuth } from "../../context/AuthContext";
import FollowupVisistsTable from "../../components/followup-visits/FollowupVisitsTable";
import DateRange from "../../components/date-range/DateRange";
import { MILLIS_IN_DAY } from "../../constants/constants";
import moment from "moment";
import { DataContext } from "../../context/DataContext";

const today = moment().startOf("day").valueOf();
const tomorrow = moment().startOf("day").add(1, "days").valueOf();
const week = moment().startOf("day").add(7, "days").valueOf();
const month = moment().startOf("month").add(1, "months").valueOf();
const UpcomingFollowups = () => {
    const { userData } = UserAuth();
    const [queries, setQueries] = useState({});
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [nextFollowupVisits, setNextFollowupVisits] = useState(null);
    const { data } = useContext(DataContext);

    useEffect(() => {
        if (userData && data?.area_code) {
            setQueries({
                ...queries,
                area_code: where("area_code", "==", data.area_code),
                next_follow_up: where("next_follow_up", "==", true),
                orderBy_date: orderBy("date", "asc"),
                start_date: where("date", ">=", today),
            });
        }
    }, [userData, data]);
    useEffect(() => {
        handleGetNextFollowUpVisits();
    }, [queries]);

    const handleGetNextFollowUpVisits = async () => {
        let fetchedData = await getVisits(queries);
        // let newData = fetchedData.sort((a, b) => a.date - b.date);
        setNextFollowupVisits(fetchedData);
    };
    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date", ">=", start), end_date: where("date", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: where("date", ">=", moment().startOf("day").valueOf()), end_date: null });
        }
    };

    const handleCalendarButtonClick = (type) => {
        if (type === "today") {
            setQueries({ ...queries, start_date: where("date", ">=", today), end_date: where("date", "<", today + MILLIS_IN_DAY) });
            setDateRange([today, today]);
        } else if (type === "tomorrow") {
            setQueries({ ...queries, start_date: where("date", ">=", tomorrow), end_date: where("date", "<", tomorrow + MILLIS_IN_DAY) });
            setDateRange([tomorrow, tomorrow]);
        } else if (type === "week") {
            setQueries({ ...queries, start_date: where("date", ">=", today), end_date: where("date", "<", week) });
            setDateRange([today, week]);
        } else if (type === "month") {
            setQueries({ ...queries, start_date: where("date", ">=", today), end_date: where("date", "<", month) });
            setDateRange([today, month]);
        }
    };
    return (
        <div>
            <Spacer mt="110px" />
            <div>
                <S.FilterContainer>
                    <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
                    <Button variant="outlined" onClick={() => handleCalendarButtonClick("today")}>
                        Today
                    </Button>
                    <Button variant="outlined" onClick={() => handleCalendarButtonClick("tomorrow")}>
                        Tomorrow
                    </Button>
                    <Button variant="outlined" onClick={() => handleCalendarButtonClick("week")}>
                        This Week
                    </Button>
                    <Button variant="outlined" onClick={() => handleCalendarButtonClick("month")}>
                        This Month
                    </Button>
                </S.FilterContainer>
                <Box sx={{ m: "16px 0px" }}>
                    <Header label="Upcoming Followups" ch="25ch" />
                </Box>
                <div>
                    <FollowupVisistsTable data={nextFollowupVisits} />
                </div>
            </div>
        </div>
    );
};

export default UpcomingFollowups;
