import { createContext, useRef, useState } from "react";

export const DataContext = createContext();
const initialData = {
    area_code: null,
    users: null,
    stores: null,
};
const DataContextProvider = ({ children }) => {
    const [data, setData] = useState(initialData);

    const handleSetData = (d) => {
        setData({ ...data, ...d });
    };

    return <DataContext.Provider value={{ data, handleSetData }}>{children}</DataContext.Provider>;
};

export default DataContextProvider;
