export const MILLIS_IN_DAY = 86400000;

export const NAV_ITEMS = [
    {
        name: "Employees",
        route: "employees",
    },
    {
        name: "Stores",
        route: "stores",
    },
];

export const AREA_CODES = [
    {
        name: "JH-01",
    },
    {
        name: "JH-02",
    },
    {
        name: "JH-03",
    },
    {
        name: "JH-04",
    },
    {
        name: "JH-05",
    },
    {
        name: "JH-06",
    },
    {
        name: "JH-07",
    },
];

export const COLORS = {
    Excited: "#54c9ea",
    Good: "#56d198",
    Neutral: "#ffca3e",
    Bad: "#ff5b42",
};

export const TITLES = {
    ASM: "ASM",
    ASO: "ASO",
    SH: "SH",
    RM: "RM",
    TSM: "TSM",
    ZSM: "ZSM",
    TL: "TL",
};

export const TITLE_VALUES = {
    ASM: ["ASM", "Area Sales Manager"],
    ASO: ["ASO", "Area Sales Oficer"],
    SH: ["SH", "State Head"],
    RM: ["RM", "Relationship Manager"],
    TSM: ["TSM", "Territory Sales Manager"],
    ZSM: ["ZSM", "Zonal Sales Manager"],
    TL: ["TL", "Team Lead"],
};
export const USER_TITLES = [
    {
        name: "Area Sales Manager",
        id: TITLES.ASM,
    },
    {
        name: "Area Sales Officer",
        id: TITLES.ASO,
    },
    {
        name: "State Head",
        id: TITLES.SH,
    },
    {
        name: "Relationship Manager",
        id: TITLES.RM,
    },
    {
        name: "Territory Sales Manager",
        id: TITLES.TSM,
    },
    {
        name: "Zonal Sales Manager",
        id: TITLES.ZSM,
    },
    {
        name: "Team Lead",
        id: TITLES.TL,
    },
];

export const LIKABILITY = {
    EXCITED: "Excited",
    GOOD: "Good",
    NEUTRAL: "Neutral",
    BAD: "bad",
};

export const RANDOM_COLORS = [
    "#E666B3",
    "#4DB3FF",
    "#1AB399",
    "#9900B3",
    "#33991A",
    "#ff6384",
    "#4D80CC",
    "#E64D66",
    "#56d198",
    "#54c9ea",
    "#ffca3e",
    "#ff5b42",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
];
