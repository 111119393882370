import React, { useContext, useEffect, useState } from "react";
import * as S from "./home.style";
import BarChart from "./../../components/charts/bar-chart/BarChart";
import Spacer from "../../components/spacer/Spacer";
import { getCounts, getEmployeeAnalysis, getVisits } from "../../functions/visit.functions";
import { DataContext } from "../../context/DataContext";
import { UserAuth } from "../../context/AuthContext";
import { orderBy, where } from "firebase/firestore";
import DateRange from "../../components/date-range/DateRange";
import { MILLIS_IN_DAY, RANDOM_COLORS } from "../../constants/constants";
import PieChart from "../../components/charts/pie-chart/PieChart";
import Header from "../../components/header/Header";
import { getPercentage } from "../../utils/global-functions";
import moment from "moment";
import FollowupVisitsTable from "../../components/followup-visits/FollowupVisitsTable";
import { isEmpty } from "lodash";

const today = moment().startOf("day").valueOf();

const Home = () => {
    const [visitPieChart, setVisitPieChart] = useState(null);
    const [demoPieChart, setDemoPieChart] = useState(null);
    const [prospectsPieChart, setProspectsPieChart] = useState(null);

    const [queries, setQueries] = useState({});
    const { data } = useContext(DataContext);
    const { userData } = UserAuth();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [todayFollowupVisits, setTodayFollowupVisits] = useState(null);
    const [barChartData, setBarChartData] = useState(null);

    useEffect(() => {
        if (data?.area_code) {
            setQueries({ ...queries, area_code: where("area_code", "==", data.area_code) });
        }
    }, [data]);
    useEffect(() => {
        if (data?.users !== null) {
            handleGetVisitsCount();
            handleGetDemosCount();
            handleGetProspectsCount();
            handleGetEmployeeAnalysis();
        }
    }, [queries, data]);

    useEffect(() => {
        if (data?.users !== null && data?.area_code !== null) {
            handleGetTodayFollowUpVisits();
        }
    }, [data]);

    const handleGetVisitsCount = async () => {
        let count = await getCounts(queries, "user", "user_id");
        if (!count || isEmpty(count)) {
            setVisitPieChart(null);
            return;
        }
        handleSetPieChart(count, setVisitPieChart, 0);
    };

    const handleGetDemosCount = async () => {
        let count = await getCounts(queries, "user", "is_demo_given");
        if (!count || isEmpty(count)) {
            setDemoPieChart(null);
            return;
        }

        handleSetPieChart(count, setDemoPieChart, 42);
    };

    const handleGetProspectsCount = async () => {
        let count = await getCounts(queries, "user", "next_follow_up");
        if (!count || isEmpty(count)) {
            setProspectsPieChart(null);
            return;
        }

        handleSetPieChart(count, setProspectsPieChart, 34);
    };

    const handleSetBarChart = (count, cb, label, color) => {
        let barChartData = {
            labels: Object.keys(count).map((k) => data?.users[k]?.name),
            datasets: [
                {
                    label: label,
                    data: Object.values(count),
                    backgroundColor: color,
                    // borderColor: "#165BAA",
                    borderWidth: 2,
                    barPercentage: 0.4,
                },
            ],
        };
        cb(barChartData);
    };

    const handleSetPieChart = (count, cb, color_id) => {
        let labels = Object.keys(count).map((k) => data?.users[k]?.name);
        let visits = Object.values(count);
        let totalVisit = visits.reduce((a, b) => a + b);

        let chartData = visits.map((v) => getPercentage(totalVisit, v));
        let colors = Object.values(count).map((item, idx) => RANDOM_COLORS[color_id + idx]);
        let pieChartData = {
            labels: labels,
            datasets: [
                {
                    data: chartData,
                    backgroundColor: colors,
                },
            ],
        };

        cb(pieChartData);
    };

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date_created", ">=", start), end_date: where("date_created", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: null, end_date: null });
        }
    };

    const handleGetTodayFollowUpVisits = async () => {
        let fetchedData = await getVisits({
            area_code: where("area_code", "==", data.area_code),
            start_date: where("date", ">=", today),
            end_date: where("date", "<", today + MILLIS_IN_DAY),
            orderBy_date: orderBy("date", "asc"),
        });
        // console.log(fetchedData);
        // console.log(data.area_code);
        setTodayFollowupVisits(fetchedData);
    };

    const handleGetEmployeeAnalysis = async () => {
        let counts = await getEmployeeAnalysis(queries);
        if (!counts) {
            setBarChartData(null);
            return;
        }
        let chartData = {
            labels: Object.keys(counts).map((k) => data?.users[k]?.name),
            datasets: [
                {
                    label: "Visits",
                    data: Object.values(counts).map((d) => d.visits),
                    backgroundColor: "#36a2eb",
                    borderWidth: 2,
                    // barPercentage: 0.4,
                },
                {
                    label: "Demos",
                    data: Object.values(counts).map((d) => d.demos),
                    backgroundColor: "#ff6384",
                    borderWidth: 2,
                    // barPercentage: 0.4,
                },
                {
                    label: "Prospects",
                    data: Object.values(counts).map((d) => d.prospects),
                    backgroundColor: "#2ae793",
                    borderWidth: 2,
                    // barPercentage: 0.4,
                },
            ],
        };
        setBarChartData(chartData);
    };
    return (
        <div>
            <Spacer mt="110px" />
            <div>
                <Header label="Today's Follow ups" full={true} />
                <S.VisitsTableContainer>
                    <FollowupVisitsTable data={todayFollowupVisits} />
                </S.VisitsTableContainer>
                <S.Divider></S.Divider>
                <S.FilterContainer>
                    <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
                </S.FilterContainer>
                <S.ChartContainer>
                    <Header label="employee analysis" full={true} />
                    <BarChart data={barChartData} />

                    <Header label="employee analysis (in percentage)" full={true} />
                    <S.PieChartContainer>
                        <S.PieChartBox>
                            <PieChart data={visitPieChart} />
                            <S.ChartLabel>Number of visits by employee</S.ChartLabel>
                        </S.PieChartBox>
                        <S.PieChartBox>
                            <PieChart data={demoPieChart} />
                            <S.ChartLabel>Number of demos given by employee</S.ChartLabel>
                        </S.PieChartBox>
                        <S.PieChartBox>
                            <PieChart data={prospectsPieChart} />
                            <S.ChartLabel>Number of prospects given by employee</S.ChartLabel>
                        </S.PieChartBox>
                    </S.PieChartContainer>

                    {/* <Header label="Number of visits by employee (in percentage)" full={true} />
                    <PieChart data={visitPieChart} />

                    <Header label="Number of demos given by employee (in percentage)" full={true} />
                    <PieChart data={demoPieChart} />

                    <Header label="Number of prospects given by employee (in percentage)" full={true} />
                    <PieChart data={prospectsPieChart} /> */}
                </S.ChartContainer>
            </div>
        </div>
    );
};

export default Home;
