import React, { useContext, useEffect, useState } from "react";
import * as S from "./visit-details.style";
import "./visit-details.css";
import { useNavigate, useParams } from "react-router-dom";
import Spacer from "../../components/spacer/Spacer";
import { getVisit, updateVisit } from "../../functions/visit.functions";
import { getStore } from "../../functions/store.functions";
import { getUser } from "../../functions/user.functions";
import HeadingContainer from "../../components/heading-container/HeadingContainer";
import { formatTime } from "../../utils/format-date";
import Chip from "../../components/chip/Chip";
import { COLORS } from "../../constants/constants";
import ModalImage from "react-modal-image";
import { Button, TextField } from "@mui/material";
import { errorToast } from "../../utils/toast";
import { addOfficeCall, getOfficeCall } from "../../functions/office-call.functions";
import { LoaderContext } from "../../context/LoaderContext";
import { nanoid } from "nanoid";
import { UserAuth } from "../../context/AuthContext";

const VisitDetails = () => {
    const { visitId } = useParams();
    const [visitData, setVisitData] = useState(null);
    const [storeData, setStoreData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [officeCallComment, setOfficeCallComment] = useState("");
    const navigate = useNavigate();
    const { setShowLoader } = useContext(LoaderContext);
    const signedInUser = UserAuth().userData;

    useEffect(() => {
        handleGetVisitDetails();
    }, []);
    const handleGetVisitDetails = async () => {
        let visit = await getVisit(visitId);
        if (!visit) {
            return;
        }
        setVisitData(visit);
        Promise.all([getStore(visit.store_id), getUser(visit.user_id), handleGetOfficeCallData(visit.office_call_id)]).then((values) => {
            setStoreData(values[0]);
            setUserData(values[1]);
            setOfficeCallComment(values[2].comment);
        });
    };

    const handleGetOfficeCallData = async (visit) => {
        let officeCallData = "";
        if (visit) {
            officeCallData = await getOfficeCall(visit);
        }

        return officeCallData;
    };

    const handleNavigation = (route, id) => {
        navigate(`/dashboard/${route}/${id}`);
    };

    const handleChangeOfficeCallComment = (event) => {
        let { value } = event.target;
        setOfficeCallComment(value);
    };

    const handleAddOfficeCall = async () => {
        if (!officeCallComment) {
            errorToast("Please write a comment!");
        }

        let payload = {
            comment: officeCallComment,
            store_id: visitData.store_id,
            user_id: visitData.user_id,
            visit_id: visitId,
            area_code: visitData.area_code,
            date_created: Date.now(),
        };
        let office_call_id = visitData.office_call_id ? visitData.office_call_id : nanoid();
        setShowLoader(true);
        try {
            await addOfficeCall(payload, office_call_id);

            if (!visitData.office_call_id) {
                handleUpdateVisit(office_call_id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoader(false);
        }
    };

    const handleUpdateVisit = async (id) => {
        try {
            await updateVisit({ office_call_id: id }, visitId);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div>
            <Spacer mt="110px" />
            <div>
                <div>
                    <HeadingContainer labels={["Visit Report"]} />
                    <S.DataContainer>
                        <S.FlexContainer>
                            <S.ColumnFlex>
                                <S.SubFlexContainer>
                                    <S.Title>Visited On :</S.Title>
                                    <S.Value>{formatTime(visitData?.date_created)}</S.Value>
                                </S.SubFlexContainer>
                                <S.SubFlexContainer>
                                    <S.Title>Area Code :</S.Title>
                                    <S.Value>{visitData?.area_code}</S.Value>
                                </S.SubFlexContainer>
                            </S.ColumnFlex>
                            <div></div>
                            <S.ImageContainer>
                                <ModalImage small={visitData?.selfie} medium={visitData?.selfie} hideDownload hideZoom className="image-viewer" />
                            </S.ImageContainer>
                        </S.FlexContainer>
                        <S.FlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Desktop POS Likability :</S.Title>
                                <Chip text={visitData?.desktop_pos_likability} color={COLORS[visitData?.desktop_pos_likability]} />
                            </S.SubFlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Mobile POS Likability :</S.Title>
                                <Chip text={visitData?.mobile_pos_likability} color={COLORS[visitData?.mobile_pos_likability]} />
                            </S.SubFlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Overall Likability :</S.Title>
                                <Chip text={visitData?.overall_likability} color={COLORS[visitData?.overall_likability]} />
                            </S.SubFlexContainer>
                        </S.FlexContainer>
                        <S.FlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Is Demo Given :</S.Title>
                                <Chip text={visitData?.is_demo_given ? "Yes" : "No"} color={visitData?.is_demo_given ? COLORS.Good : COLORS.Bad} />
                            </S.SubFlexContainer>
                            {visitData?.is_demo_given && (
                                <>
                                    {visitData?.demo_given_to && (
                                        <S.SubFlexContainer>
                                            <S.Title>Demo Given To :</S.Title>
                                            <S.Value>{visitData?.demo_given_to}</S.Value>
                                        </S.SubFlexContainer>
                                    )}
                                    {visitData?.demo_receiver_name && (
                                        <S.SubFlexContainer>
                                            <S.Title>Name :</S.Title>
                                            <S.Value>{visitData?.demo_receiver_name}</S.Value>
                                        </S.SubFlexContainer>
                                    )}
                                </>
                            )}
                        </S.FlexContainer>
                        <S.FlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Desktop POS Installed :</S.Title>
                                <Chip
                                    text={visitData?.is_desktop_pos_installed ? "Yes" : "No"}
                                    color={visitData?.is_desktop_pos_installed ? COLORS.Good : COLORS.Bad}
                                />
                            </S.SubFlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Mobile POS Installed :</S.Title>
                                <Chip
                                    text={visitData?.is_mobile_pos_installed ? "Yes" : "No"}
                                    color={visitData?.is_mobile_pos_installed ? COLORS.Good : COLORS.Bad}
                                />
                            </S.SubFlexContainer>
                            {(visitData?.is_desktop_pos_installed || visitData?.is_mobile_pos_installed) && (
                                <S.SubFlexContainer>
                                    <S.Title>Is Paid :</S.Title>
                                    <Chip text={visitData?.is_paid ? "Yes" : "No"} color={visitData?.is_paid ? COLORS.Good : COLORS.Bad} />
                                </S.SubFlexContainer>
                            )}
                        </S.FlexContainer>
                        <S.CommentBoxContainer>
                            <S.Title>Comments :</S.Title>
                            <S.CommentBox>{visitData?.store_comment}</S.CommentBox>
                        </S.CommentBoxContainer>
                        {visitData?.store_requirement && (
                            <S.SubFlexContainer>
                                <S.Title>Store Requirement :</S.Title>
                                <S.Value>{visitData?.store_requirement}</S.Value>
                            </S.SubFlexContainer>
                        )}

                        <S.FlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Next Follow Up :</S.Title>
                                <Chip text={visitData?.next_folow_up ? "Yes" : "No"} color={visitData?.next_folow_up ? COLORS.Good : COLORS.Bad} />
                            </S.SubFlexContainer>
                            {visitData?.next_folow_up && (
                                <>
                                    {" "}
                                    <S.SubFlexContainer>
                                        <S.Title>How :</S.Title>
                                        <S.Value>{visitData?.how}</S.Value>
                                    </S.SubFlexContainer>
                                    <S.SubFlexContainer>
                                        <S.Title>When :</S.Title>
                                        <S.Value>{formatTime(visitData?.date)}</S.Value>
                                    </S.SubFlexContainer>
                                </>
                            )}
                        </S.FlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Office Call Feedback :</S.Title>
                            <TextField
                                sx={{ width: "70%" }}
                                value={officeCallComment}
                                placeholder="Enter your comment here..."
                                onChange={handleChangeOfficeCallComment}
                                disabled={!signedInUser?.permissions?.office_calls}
                            />
                            <Button
                                onClick={handleAddOfficeCall}
                                variant="contained"
                                disabled={!signedInUser?.permissions?.office_calls}
                                size="large"
                                sx={{
                                    padding: "13.5px",
                                    width: "40px",
                                    backgroundColor: "#2E65F3",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "#2979ff",
                                    },
                                }}
                            >
                                Save
                            </Button>
                        </S.SubFlexContainer>
                    </S.DataContainer>
                </div>

                {/* user data and store data */}
                <div>
                    <HeadingContainer labels={["Visited Store", "Visited By"]} />
                    <S.LowerDataContainer>
                        <S.StoreDataContainer>
                            <div>
                                <ModalImage small={storeData?.image} medium={storeData?.image} hideDownload hideZoom className="image-viewer" />
                            </div>
                            <div>
                                <S.SubFlexContainer>
                                    <S.Title>Store Name :</S.Title>
                                    <S.Value link={true} onClick={() => handleNavigation("stores", visitData.store_id)}>
                                        {storeData?.name}
                                    </S.Value>
                                </S.SubFlexContainer>
                                <S.SubFlexContainer>
                                    <S.Title>Address :</S.Title>
                                    <S.Value>{storeData?.address}</S.Value>
                                </S.SubFlexContainer>
                                <S.SubFlexContainer>
                                    <S.Title>Area Code :</S.Title>
                                    <S.Value>{storeData?.area_code}</S.Value>
                                </S.SubFlexContainer>
                                <S.SubFlexContainer>
                                    <S.Title>Phone :</S.Title>
                                    <S.Value>{storeData?.phone}</S.Value>
                                </S.SubFlexContainer>
                                <S.SubFlexContainer>
                                    <S.Title>Created On :</S.Title>
                                    <S.Value>{formatTime(storeData?.date_created)}</S.Value>
                                </S.SubFlexContainer>
                            </div>
                        </S.StoreDataContainer>
                        <S.UserDataContainer>
                            <S.SubFlexContainer>
                                <S.Title>Name :</S.Title>
                                <S.Value link={true} onClick={() => handleNavigation("users", visitData.user_id)}>
                                    {userData?.name}
                                </S.Value>
                            </S.SubFlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Title :</S.Title>
                                <S.Value>{userData?.title}</S.Value>
                            </S.SubFlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Area Codes :</S.Title>
                                <S.Value>{userData?.area_code.join(", ")}</S.Value>
                            </S.SubFlexContainer>
                            <S.SubFlexContainer>
                                <S.Title>Phone :</S.Title>
                                <S.Value>{userData?.phone}</S.Value>
                            </S.SubFlexContainer>
                            {userData?.email && (
                                <S.SubFlexContainer>
                                    <S.Title>Email :</S.Title>
                                    <S.Value>{userData?.email}</S.Value>
                                </S.SubFlexContainer>
                            )}
                        </S.UserDataContainer>
                    </S.LowerDataContainer>
                </div>
            </div>
        </div>
    );
};

export default VisitDetails;
