import styled from "@emotion/styled/macro";

export const StoreDataContainer = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    box-sizing: border-box;
    padding: 20px 30px;
`;

export const StoreSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const FlexContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
`;

export const SubFlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Value = styled.div`
    color: #1c1c1c;
    font-size: 14px;
    ${(props) => props.link && "cursor: pointer"};

    :hover {
        ${(props) => props.link && "text-decoration: underline"};
        ${(props) => props.link && "color: blue"};
    }
`;
export const Title = styled.div`
    font-weight: 600;
`;

export const DataContainer = styled.div`
    box-sizing: border-box;
    padding: 20px 30px;
`;

export const AddressBox = styled.div`
    width: 70%;
`;
export const AddressBoxContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const FilterContainer = styled.div`
    margin-bottom: 12px;
`;
