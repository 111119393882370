import { AppBar, Avatar, Box, Button, CssBaseline, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { NAV_LINKS } from "../../constants/routeConstants";
import AreaCode from "../area-code/AreaCode";
import HomeDropLogo from "../../assets/images/homedrop-logo.svg";

const Navbar = () => {
    const navigate = useNavigate();
    const { logOut } = UserAuth();
    const location = useLocation();
    const path = location.pathname.split("/")[2];

    const handleNavigation = (route) => {
        navigate(`/dashboard/${route}`);
    };

    const handleSignout = () => {
        logOut();
    };

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar component="nav" sx={{ background: "#253238", p: 1 }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }} onClick={() => handleNavigation("home")}>
                            <Avatar alt="HomeDrop Logo" src={HomeDropLogo} />
                            <Typography variant="h6" sx={{ display: { sm: "block" }, color: "#FFDE2C" }}>
                                Dashboard
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <AreaCode />

                            <Box sx={{ display: { sm: "block" }, marginLeft: "20px" }}>
                                {NAV_LINKS.map((item) => (
                                    <Button
                                        key={item.name}
                                        sx={{ color: `${path === item.route ? "#FFDE2C" : "#fff"}` }}
                                        onClick={() => handleNavigation(item.route)}
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                                <Button variant="outlined" color="error" sx={{ marginLeft: "10px" }} onClick={handleSignout}>
                                    SignOut
                                </Button>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Outlet />
        </>
    );
};

export default Navbar;
