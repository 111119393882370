import styled from "@emotion/styled/macro";

export const ChartContainer = styled.div`
    width: 370px;
    height: 370px;
`;

export const LegendRow = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
`;
export const LegendBox = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
`;
export const LegendTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #475569;
    text-decoration: ${(props) => props.strike && "line-through"};
`;
export const LegendContainer = styled.div`
    width: 450px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    margin-top: 12px;
`;
