import React, { useContext, useEffect, useState } from "react";
import * as S from "./users.style";
import DateRange from "../../components/date-range/DateRange";
import { MILLIS_IN_DAY, TITLE_VALUES, USER_TITLES } from "../../constants/constants";
import { Box, Typography } from "@mui/material";
import UsersTable from "../../components/users/users-table/UsersTable";
import { getUsers } from "../../functions/user.functions";
import { where } from "firebase/firestore";
import AreaCode from "../../components/area-code/AreaCode";
import { DataContext } from "../../context/DataContext";
import { UserAuth } from "../../context/AuthContext";
import CustomAutoComplete from "../../components/custom-auto-complete/CustomAutoComplete";
import Header from "../../components/header/Header";

const Users = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [queries, setQueries] = useState({});
    const [users, setUsers] = useState(null);
    const { data } = useContext(DataContext);
    const { userData } = UserAuth();

    useEffect(() => {
        handleGetEmployeesData();
    }, [queries]);

    useEffect(() => {
        handleAreaCodeChange();
    }, [data.area_code]);

    //TEMP
    // useEffect(() => {
    //     if (userData) {
    //         setQueries({ ...queries, area_code: where("area_code", "array-contains", data.area_code) });
    //     }
    // }, [userData]);

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date_created", ">=", start), end_date: where("date_created", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: null, end_date: null });
        }
    };

    const handleGetEmployeesData = async () => {
        let fetchedData = await getUsers(queries);
        setUsers(fetchedData);
    };

    const handleAreaCodeChange = () => {
        if (data.area_code) {
            setQueries({ ...queries, area_code: where("area_code", "array-contains", data.area_code) });
        } else {
            setQueries({ ...queries, area_code: null });
        }
    };

    const handleSelect = (value, key) => {
        if (key === "title") {
            if (value[key] !== null) {
                setQueries({ ...queries, [key]: where(key, "in", TITLE_VALUES[value[key]]) });
            } else {
                setQueries({ ...queries, [key]: null });
            }
        } else {
            if (value[key] !== null) {
                setQueries({ ...queries, [key]: where(key, "==", value[key]) });
            } else {
                setQueries({ ...queries, [key]: null });
            }
        }
    };

    return (
        <S.Container>
            <S.FilterContainer>
                <CustomAutoComplete
                    data={data?.users ? Object.values(data?.users) : []}
                    label="Select User"
                    onSelectChange={(value) => handleSelect(value, "user_id")}
                    type="user_id"
                />
                <CustomAutoComplete data={USER_TITLES} label="Select Title" onSelectChange={(value) => handleSelect(value, "title")} type="title" />
                <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
            </S.FilterContainer>
            <div>
                <Box sx={{ m: "12px 0px" }}>
                    <Header label="Users" />
                </Box>
                <div>
                    <UsersTable data={users} />
                </div>
            </div>
        </S.Container>
    );
};

export default Users;
