import styled from "@emotion/styled/macro";

export const FilterContainer = styled.div`
    padding: 0 12px 12px 12px;
    box-shadow: 0px 10px 10px -10px rgba(99, 99, 99, 0.2);
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin: 16px 0 24px;
`;

export const VisitsTableContainer = styled.div`
    margin: 16px auto 24px;
    width: 90%;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin: 12px auto;
    border: 1px dashed #253238;
`;

export const PieChartContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 16px 0;
    row-gap: 50px;
`;
export const ChartLabel = styled.div`
    color: rgba(0, 0, 0, 0.64);
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.64);
`;

export const PieChartBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;
