import "./App.css";
import { Container } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/sign-in/SignIn";
import Navbar from "./components/navbar/Navbar";
import Users from "./pages/users/Users";
import Stores from "./pages/stores/Stores";
import IndexPage from "./pages/index-page/IndexPage";
import Loader from "./components/loader/Loader";
import Home from "./pages/home/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Visits from "./pages/visits/Visits";
import { UserAuth } from "./context/AuthContext";
import { useContext, useEffect } from "react";
import { getStores } from "./functions/store.functions";
import { getUsers } from "./functions/user.functions";
import { DataContext } from "./context/DataContext";
import StoreDetails from "./pages/store-details/StoreDetails";
import UserDetails from "./pages/user-details/UserDetails";
import VisitDetails from "./pages/visit-details/VisitDetails";
import { where } from "firebase/firestore";
import Reports from "./pages/reports/Reports";
import UpcomingFollowups from "./pages/upcoming-followups/UpcomingFollowups";

function App() {
    const { user, userData } = UserAuth();
    const { handleSetData, data } = useContext(DataContext);

    useEffect(() => {
        if (user !== null && userData !== null && data?.area_code !== null) {
            handleData();
        }
    }, [user, userData, data.area_code]);

    const handleData = async () => {
        let stores = await handleGetAllStoresData();
        let users = await handleGetAllUsersData();
        handleSetData({ stores: stores, users: users });
    };

    const handleGetAllStoresData = async () => {
        let stores = await getStores();
        let formatData = {};
        for (let i = 0; i < stores.length; i++) {
            let id = stores[i].id;
            let name = stores[i].name;
            let address = stores[i].address;
            formatData[id] = {
                id,
                name,
                address,
            };
        }

        return formatData;
    };
    const handleGetAllUsersData = async () => {
        let users = await getUsers();
        let formatData = {};
        for (let i = 0; i < users.length; i++) {
            let id = users[i].id;
            let name = users[i].name;
            formatData[id] = {
                id,
                name,
            };
        }
        return formatData;
    };
    return (
        <Container component="main">
            <ToastContainer />
            <Loader />
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/dashboard" element={<Navbar />}>
                    <Route path="home" element={<Home />} />
                    <Route path="stores" element={<Stores />} />
                    <Route path="stores/:storeId" element={<StoreDetails />} />
                    <Route path="users" element={<Users />} />
                    <Route path="users/:userId" element={<UserDetails />} />
                    <Route path="visits" element={<Visits />} />
                    <Route path="visits/:visitId" element={<VisitDetails />} />
                    <Route path="reports" element={<Reports />} />
                    <Route path="upcoming-followups" element={<UpcomingFollowups />} />
                </Route>
            </Routes>
        </Container>
    );
}

export default App;
