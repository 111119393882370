import React from "react";
import * as S from "./style";
import { Line } from "react-chartjs-3";
import EmptyChart from "../../empty-chart-container/EmptyChart";

const chartData = {
    labels: ["a", "b", "c", "d", "e"],
    datasets: [
        {
            label: "Customer",
            data: [2, 4, 12, 4, 18],
            borderColor: "#FFA5CB",
            extraData: [1, 2, 5, 3, 4],
            fill: false,
            tension: 0,
        },
    ],
};
const LineChart = ({ data }) => {
    if (!data) {
        return <EmptyChart />;
    }
    return (
        <S.ChartContainer>
            <Line
                data={data}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                    legend: {
                        position: "bottom",
                        align: "start",
                        onClick: () => {
                            return;
                        },
                    },
                    tooltips: {
                        intersect: true,
                    },
                }}
            />
        </S.ChartContainer>
    );
};

export default LineChart;
