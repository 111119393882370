import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
`;

export const FilterContainer = styled.div`
    padding: 0 12px 12px 12px;
    box-shadow: 0px 10px 10px -10px rgba(99, 99, 99, 0.2);
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 16px;
    margin: 16px 0 24px;
`;

export const DataContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;
`;

export const DataBox = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 12px;
`;

export const Label = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #94a3b8;
`;
export const Value = styled.div`
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #263238;
    letter-spacing: -0.0375em;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin: 12px auto;
    border: 1px dashed #253238;
`;

export const PieChartContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 16px 0;
`;

export const PieChartBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const ChartLabel = styled.div`
    color: rgba(0, 0, 0, 0.64);
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.64);
`;

export const MeetingPieChartContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 16px 0;
    row-gap: 50px;
`;
