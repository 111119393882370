import React from "react";
import * as S from "./style";
import FollowupRow from "./FollowupRow";
import EmptyChart from "../empty-chart-container/EmptyChart";

const FollowupVisitsTable = ({ data }) => {
    return (
        <div>
            <S.TableHeadRow>
                <S.TableHead flex="0.5" $center>
                    Sl. No
                </S.TableHead>
                <S.TableHead>Date Created</S.TableHead>
                <S.TableHead>User</S.TableHead>
                <S.TableHead>Store</S.TableHead>
                <S.TableHead flex="3">Address</S.TableHead>
                <S.TableHead>When</S.TableHead>
            </S.TableHeadRow>
            <S.TableBody>
                {data?.length ? (
                    data?.map((visit, idx) => (
                        <React.Fragment key={idx}>
                            <FollowupRow key={idx} visit={visit} idx={idx} />
                            <S.Divider></S.Divider>
                        </React.Fragment>
                    ))
                ) : (
                    <EmptyChart />
                )}
            </S.TableBody>
        </div>
    );
};

export default FollowupVisitsTable;
