import { Autocomplete, Box, TextField, createFilterOptions } from "@mui/material";
import React, { useState } from "react";

const filter = createFilterOptions();

const CustomAutoComplete = ({ data, label, onSelectChange, type }) => {
    const [value, setValue] = useState(null);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: "10px",
                }}
            >
                <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                            setValue({
                                name: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                name: newValue.inputValue,
                            });
                        } else if (newValue) {
                            setValue(newValue);
                            onSelectChange({ [type]: newValue.id });
                        } else {
                            setValue(null);
                            onSelectChange({ [type]: null });
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.name);
                        if (inputValue !== "" && !isExisting) {
                            // filtered.push({
                            //     inputValue,
                            //     name: `Add New Store`,
                            // });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id={type}
                    options={data}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(props, option) => (
                        <li key={Math.random()} {...props}>
                            {option.name}
                        </li>
                    )}
                    sx={{ width: 250 }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label={label} />}
                />
            </Box>
        </Box>
    );
};

export default CustomAutoComplete;
