import styled from "@emotion/styled/macro";

export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    padding: 20px 30px;
`;
export const FlexContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
`;

export const SubFlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    // border: 1px solid red;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Image = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;
`;

export const Value = styled.div`
    color: #1c1c1c;
    font-size: 14px;
    ${(props) => props.link && "cursor: pointer"};

    :hover {
        ${(props) => props.link && "text-decoration: underline"};
        ${(props) => props.link && "color: blue"};
    }
`;
export const Title = styled.div`
    font-weight: 600;
`;

export const CommentBox = styled.div`
    width: 500px;
`;
export const CommentBoxContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const ColumnFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StoreDataContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;
export const UserDataContainer = styled.div`
    margin-left: 20px;
`;

export const LowerDataContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    box-sizing: border-box;
    padding: 20px 30px;
`;
