import React, { useContext, useEffect, useState } from "react";
import * as S from "./visits.style";
import DateRange from "../../components/date-range/DateRange";
import { MILLIS_IN_DAY } from "../../constants/constants";
import { Box, TextField, Typography } from "@mui/material";
import { orderBy, where } from "firebase/firestore";
import { getVisits } from "../../functions/visit.functions";
import VisitsTable from "../../components/visits/visits-table/VisitsTable";
import AreaCode from "../../components/area-code/AreaCode";
import { DataContext } from "../../context/DataContext";
import { UserAuth } from "../../context/AuthContext";
import CustomAutoComplete from "../../components/custom-auto-complete/CustomAutoComplete";
import Header from "../../components/header/Header";
import { getOfficeCalls } from "../../functions/office-call.functions";
import OfficeCallVisitsTable from "../../components/office-call-visits/table/OfficeCallVisitsTable";

const Visits = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [visitsData, setVisitsData] = useState(null);
    const [officeCallVisitsData, setOfficeCallVisitsData] = useState(null);
    const [queries, setQueries] = useState({});
    const { data } = useContext(DataContext);
    const { userData } = UserAuth();

    useEffect(() => {
        handleGetVisitsData();
        handleGetOfficeCallVisitsData();
    }, [queries]);

    useEffect(() => {
        handleAreaCodeChange();
    }, [data.area_code]);

    //TEMP
    useEffect(() => {
        if (data?.area_code) {
            setQueries({ ...queries, area_code: where("area_code", "==", data.area_code) });
        }
    }, [data]);

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date_created", ">=", start), end_date: where("date_created", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: null, end_date: null });
        }
    };

    const handleGetVisitsData = async () => {
        let fetchedData = await getVisits(queries);
        setVisitsData(fetchedData);
    };
    const handleGetOfficeCallVisitsData = async () => {
        let fetchedData = await getOfficeCalls(queries);
        setOfficeCallVisitsData(fetchedData);
    };

    const handleAreaCodeChange = () => {
        if (data.area_code) {
            setQueries({ ...queries, area_code: where("area_code", "==", data.area_code) });
        } else {
            setQueries({ ...queries, area_code: null });
        }
    };

    const handleSelect = (value, key) => {
        if (value[key] !== null) {
            setQueries({ ...queries, [key]: where(key, "==", value[key]) });
        } else {
            setQueries({ ...queries, [key]: null });
        }
    };

    return (
        <S.Container>
            <S.FilterContainer>
                <CustomAutoComplete
                    data={data?.stores ? Object.values(data?.stores) : []}
                    label="Select Store"
                    onSelectChange={(value) => handleSelect(value, "store_id")}
                    type="store_id"
                />
                <CustomAutoComplete
                    data={data?.users ? Object.values(data?.users) : []}
                    label="Select User"
                    onSelectChange={(value) => handleSelect(value, "user_id")}
                    type="user_id"
                />
                <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
            </S.FilterContainer>
            <div>
                <Box sx={{ m: "12px 0px" }}>
                    <Header label="Visits" />
                </Box>
                <div>
                    <VisitsTable data={visitsData} />
                </div>
            </div>
            {userData?.permissions?.office_calls && (
                <div>
                    <Box sx={{ m: "12px 0px" }}>
                        <Header label="Office Call Visits" ch="22ch" />
                    </Box>
                    <div>
                        <OfficeCallVisitsTable data={officeCallVisitsData} />
                    </div>
                </div>
            )}
        </S.Container>
    );
};

export default Visits;
