import { collection, doc, getDoc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { formatDate } from "../utils/format-date";
import { MILLIS_IN_DAY } from "../constants/constants";
import { isObject } from "lodash";

const getVisit = async (visitId) => {
    const docRef = doc(db, "visits", visitId);
    let visitData = null;
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            visitData = docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (err) {
        console.log(err);
    }

    return visitData;
};

const getVisits = async (queries = {}) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "visits"), ...queryArray, orderBy("date_created", "desc"));
    let fetchedVisits = [];
    try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedVisits.push({ ...doc.data(), id: doc.id });
        });
    } catch (err) {
        console.log(err);
    }
    return fetchedVisits;
};

const getCounts = async (queries = {}, base, type) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "visits"), ...queryArray, orderBy("date_created", "desc"));
    let counts = null;
    try {
        let fetchedData = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedData.push({ ...doc.data(), id: doc.id });
        });
        if (base === "user") {
            counts = _getCountsForUser(fetchedData, type);
        } else if (base === "date") {
            counts = _getCountsForDate(fetchedData, type);
        }
    } catch (err) {
        console.log(err);
    }
    return counts;
};

const _getCountsForUser = (data, type) => {
    if (data.length <= 0) return;
    let result = {};
    for (let i = 0; i < data.length; i++) {
        if (!data[i].user_id) {
            continue;
        }
        if (data[i][type]) {
            if (!result[data[i].user_id]) {
                result[data[i].user_id] = 1;
            } else {
                result[data[i].user_id] = result[data[i].user_id] + 1;
            }
        }
    }

    return result;
};

const _getCountsForDate = (data, type) => {
    if (data.length <= 0) return;
    let result = {};
    for (let i = 0; i < data.length; i++) {
        if (data[i].date_created === undefined) {
            continue;
        }
        let date = formatDate(data[i].date_created);
        if (data[i][type]) {
            if (!result[date]) {
                result[date] = 1;
            } else {
                result[date] = result[date] + 1;
            }
        } else {
            // result[date] = 0;
            if (!result[date]) {
                result[date] = 0;
            }
        }
    }
    return result;
};

const getMeetingAnalysis = async (queries = {}) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "visits"), ...queryArray, orderBy("date_created", "desc"));
    let result = null;
    try {
        let fetchedData = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedData.push({ ...doc.data(), id: doc.id });
        });
        result = _getMeetingAnalysis(fetchedData);
    } catch (err) {
        console.log(err);
    }
    return result;
};

const _getMeetingAnalysis = (data) => {
    if (data.length <= 0) return;
    let overall_likability = {};
    let desktop_likability = {};
    let mobile_likability = {};
    let demo_given = {};
    let follow_up = {};
    let res = {};
    for (let i = 0; i < data.length; i++) {
        let visit = data[i];
        if (visit.overall_likability) {
            if (!overall_likability[visit.overall_likability]) {
                overall_likability[visit.overall_likability] = 1;
            } else {
                overall_likability[visit.overall_likability] = overall_likability[visit.overall_likability] + 1;
            }
        }
        if (visit.desktop_pos_likability) {
            if (!desktop_likability[visit.desktop_pos_likability]) {
                desktop_likability[visit.desktop_pos_likability] = 1;
            } else {
                desktop_likability[visit.desktop_pos_likability] = desktop_likability[visit.desktop_pos_likability] + 1;
            }
        }
        if (visit.mobile_pos_likability) {
            if (!mobile_likability[visit.mobile_pos_likability]) {
                mobile_likability[visit.mobile_pos_likability] = 1;
            } else {
                mobile_likability[visit.mobile_pos_likability] = mobile_likability[visit.mobile_pos_likability] + 1;
            }
        }
        if (visit.is_demo_given === true) {
            if (!demo_given["Yes"]) {
                demo_given["Yes"] = 1;
            } else {
                demo_given["Yes"] = demo_given["Yes"] + 1;
            }
        } else if (visit.is_demo_given === false) {
            if (!demo_given["No"]) {
                demo_given["No"] = 1;
            } else {
                demo_given["No"] = demo_given["No"] + 1;
            }
        }

        if (visit.next_follow_up === true) {
            if (!follow_up["Yes"]) {
                follow_up["Yes"] = 1;
            } else {
                follow_up["Yes"] = follow_up["Yes"] + 1;
            }
        } else if (visit.next_follow_up === false) {
            if (!follow_up["No"]) {
                follow_up["No"] = 1;
            } else {
                follow_up["No"] = follow_up["No"] + 1;
            }
        }
    }

    res = {
        overall_likability,
        desktop_likability,
        mobile_likability,
        demo_given,
        next_follow_up: follow_up,
    };
    return res;
};

const getEmployeeAnalysis = async (queries = {}) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "visits"), ...queryArray, orderBy("date_created", "desc"));
    let result = null;
    try {
        let fetchedData = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedData.push({ ...doc.data(), id: doc.id });
        });
        result = _getEmployeeAnalysis(fetchedData);
    } catch (err) {
        console.log(err);
    }
    return result;
};

const _getEmployeeAnalysis = (data) => {
    if (data.length <= 0) return;
    let result = {};

    for (let i = 0; i < data.length; i++) {
        let visit = data[i];
        if (!visit.user_id) {
            continue;
        }
        if (!isObject(result[visit.user_id])) {
            result[visit.user_id] = {
                visits: 0,
                demos: 0,
                prospects: 0,
            };
        }
        if (visit["user_id"]) {
            result[visit.user_id].visits = result[visit.user_id].visits + 1;
        }
        if (visit["is_demo_given"]) {
            result[visit.user_id].demos = result[visit.user_id].demos + 1;
        }
        if (visit["next_follow_up"]) {
            result[visit.user_id].prospects = result[visit.user_id].prospects + 1;
        }
    }
    return result;
};

const updateVisit = async (payload, id) => {
    const visitsCollection = collection(db, "visits");

    // Define the document reference
    const docRef = doc(visitsCollection, id);
    try {
        // Add or update the document
        await setDoc(docRef, payload, { merge: true });

        // Log a success message
    } catch (e) {
        console.log(e);
    }
};

export { getVisit, getVisits, getCounts, getMeetingAnalysis, getEmployeeAnalysis, updateVisit };
