import React from "react";
import * as S from "./style";
import { useNavigate } from "react-router-dom";

const UsersTableRow = ({ user, idx }) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(`/dashboard/users/${user.id}`);
    };
    return (
        <S.TableRow key={idx} onClick={handleNavigate}>
            <S.TableData flex="0.2" $center>
                {idx + 1}.
            </S.TableData>
            <S.TableData $center flex="0.5">
                {user?.area_code.join(", ")}
            </S.TableData>
            <S.TableData>{user?.title}</S.TableData>
            <S.TableData>{user?.name}</S.TableData>
            <S.TableData>{user?.phone}</S.TableData>
        </S.TableRow>
    );
};

export default UsersTableRow;
