import React from "react";
import styled from "@emotion/styled/macro";

const Wrapper = styled.div`
    background: ${(props) => (props.bg ? props.bg : "#394457")};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
    font-size: 14px;
    border-radius: 14px;
    width: 8ch;
`;

const Chip = ({ text, color }) => {
    return <Wrapper bg={color}>{text}</Wrapper>;
};

export default Chip;
