import React from "react";
import * as S from "./style";

const HeadingContainer = ({ labels = [], switchable = false, activeHead = null, onClickHead }) => {
    const handleHeadClick = (item) => {
        if (onClickHead) {
            onClickHead(item);
        }
    };

    return (
        <S.Container count={labels.length} switchable={switchable}>
            {labels.map((item) => (
                <div key={item}>
                    <S.Head switchable={switchable} ch={item.length} activeHead={activeHead === item} onClick={() => handleHeadClick(item)}>
                        {item}
                    </S.Head>
                </div>
            ))}
            {switchable && <S.Hint>(Switch tabs for more information)</S.Hint>}
        </S.Container>
    );
};

export default HeadingContainer;
