import React from "react";
import styled from "@emotion/styled/macro";

const Container = styled.div`
    margin-top: ${(props) => props.mt && props.mt};
`;

const Spacer = ({ mt }) => {
    return <Container mt={mt}></Container>;
};

export default Spacer;
