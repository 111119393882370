import styled from "@emotion/styled/macro";

export const EmptyContainer = styled.div`
    width: 100%;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyText = styled.div`
    background: rgba(0, 0, 0, 0.64);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    padding: 8px;
`;
