import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";

const getStore = async (storeId) => {
    const docRef = doc(db, "stores", storeId);
    let storeData = null;
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            storeData = docSnap.data();
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (err) {
        console.log(err);
    }

    return storeData;
};

const getStores = async (queries = {}) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "stores"), ...queryArray, orderBy("date_created", "desc"));
    let fetchedStores = [];
    try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedStores.push({ ...doc.data(), id: doc.id });
        });
    } catch (err) {
        console.log(err);
    }
    return fetchedStores;
};

const getStoreAnalysis = async (queries = {}) => {
    let queryArray = Object.values(queries).filter((q) => q !== null);
    let q = query(collection(db, "stores"), ...queryArray, orderBy("date_created", "desc"));
    let result = null;
    try {
        let fetchedData = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            fetchedData.push({ ...doc.data(), id: doc.id });
        });
        result = _getStoreAnalysis(fetchedData);
    } catch (err) {
        console.log(err);
    }
    return result;
};

const _getStoreAnalysis = (data) => {
    if (data.length <= 0) return;
    let onlineOrderResult = {};
    let usingPOSResult = {};
    let totalStores = data.length;

    let totalSqFeet = 0;
    let sqFeetCount = 0;

    let totalEmployees = 0;
    let storeWithEmployees = 0;

    let storeWithOnlineOrders = 0;
    let storeWithExistingPOS = 0;

    for (let i = 0; i < data.length; i++) {
        let store = data[i];
        if (store.sq_feet) {
            totalSqFeet += Number(store.sq_feet);
            sqFeetCount++;
        }
        if (store.employee_count) {
            totalEmployees += Number(store.employee_count);
            storeWithEmployees++;
        }
        if (store.is_online) {
            storeWithOnlineOrders++;
            if (!onlineOrderResult[store.online_software]) {
                onlineOrderResult[store.online_software] = 1;
            } else {
                onlineOrderResult[store.online_software] = onlineOrderResult[store.online_software] + 1;
            }
        }
        if (store.is_existing_software) {
            storeWithExistingPOS++;
            if (!usingPOSResult[store.existing_software]) {
                usingPOSResult[store.existing_software] = 1;
            } else {
                usingPOSResult[store.existing_software] = usingPOSResult[store.existing_software] + 1;
            }
        }
    }

    let avg_sq_feet = Math.round(totalSqFeet / sqFeetCount);
    let avg_employee = Math.round(totalEmployees / storeWithEmployees);
    let online_order_percentage = Math.round((storeWithOnlineOrders / totalStores) * 100);
    let billing_software_percentage = Math.round((storeWithExistingPOS / totalStores) * 100);

    let result = {
        avg_sq_feet,
        avg_employee,
        online_order_percentage,
        billing_software_percentage,
        online_order_data: onlineOrderResult,
        using_billing_software_data: usingPOSResult,
    };

    return result;
};

export { getStore, getStores, getStoreAnalysis };
