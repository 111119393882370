import React, { useContext, useEffect, useState } from "react";
import * as S from "./store-details.style";
import "./store-details.css";
import Spacer from "../../components/spacer/Spacer";
import HeadingContainer from "../../components/heading-container/HeadingContainer";
import { useNavigate, useParams } from "react-router-dom";
import { getStore } from "../../functions/store.functions";
import ModalImage from "react-modal-image";
import { formatTime } from "../../utils/format-date";
import { DataContext } from "../../context/DataContext";
import Chip from "../../components/chip/Chip";
import { COLORS, MILLIS_IN_DAY } from "../../constants/constants";
import { getUser } from "../../functions/user.functions";
import VisitsTable from "../../components/visits/visits-table/VisitsTable";
import { getVisits } from "../../functions/visit.functions";
import { where } from "firebase/firestore";
import DateRange from "../../components/date-range/DateRange";

const StoreDetails = () => {
    const { storeId } = useParams();
    const { data } = useContext(DataContext);
    const [storeData, setStoreData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [visitsData, setVisitsData] = useState(null);
    const [queries, setQueries] = useState({ store_id: where("store_id", "==", storeId) });
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const navigate = useNavigate();

    useEffect(() => {
        handleGetStoreDetails();
    }, []);

    useEffect(() => {
        handleGetVisitsData();
    }, [queries]);

    const handleGetStoreDetails = async () => {
        let store = await getStore(storeId);
        if (!store) return;
        setStoreData(store);
        Promise.all([getUser(store.user_id), handleGetVisitsData]).then((values) => {
            setUserData(values[0]);
        });
    };

    const handleGetVisitsData = async () => {
        let visits = await getVisits(queries);
        setVisitsData(visits);
    };

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date_created", ">=", start), end_date: where("date_created", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: null, end_date: null });
        }
    };

    const handleNavigation = (route, id) => {
        navigate(`/dashboard/${route}/${id}`);
    };

    return (
        <div>
            <Spacer mt="110px" />
            {/* store detail */}
            <div>
                <HeadingContainer labels={["Store Detail"]} />
                <S.StoreDataContainer>
                    <S.StoreSubContainer flex="0.4">
                        <ModalImage small={storeData?.image} medium={storeData?.image} hideDownload hideZoom className="store-image" />
                    </S.StoreSubContainer>
                    <S.StoreSubContainer flex="0.5">
                        <S.SubFlexContainer>
                            <S.Title>Store Name :</S.Title>
                            <S.Value>{storeData?.name}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Phone :</S.Title>
                            <S.Value>{storeData?.phone}</S.Value>
                        </S.SubFlexContainer>
                        {storeData?.email && (
                            <S.SubFlexContainer>
                                <S.Title>Email :</S.Title>
                                <S.Value>{storeData?.email}</S.Value>
                            </S.SubFlexContainer>
                        )}
                        <S.AddressBoxContainer>
                            <S.Title>Address :</S.Title>
                            <S.AddressBox>{storeData?.address}</S.AddressBox>
                        </S.AddressBoxContainer>
                        <S.SubFlexContainer>
                            <S.Title>Area Code :</S.Title>
                            <S.Value>{storeData?.area_code}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Created On :</S.Title>
                            <S.Value>{formatTime(storeData?.dae_created)}</S.Value>
                        </S.SubFlexContainer>
                    </S.StoreSubContainer>
                    <S.StoreSubContainer>
                        <S.SubFlexContainer>
                            <S.Title>Added By :</S.Title>
                            <S.Value>{data.users[storeData?.user_id]?.name}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Employee Count :</S.Title>
                            <S.Value>{storeData?.employee_count}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Square Feet Area :</S.Title>
                            <S.Value>{storeData?.sq_feet}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Any Existing Billing Software :</S.Title>
                            <Chip
                                text={storeData?.is_existing_software ? "Yes" : "No"}
                                color={storeData?.is_existing_software ? COLORS.Good : COLORS.Bad}
                            />
                        </S.SubFlexContainer>
                        {storeData?.is_existing_software && (
                            <>
                                <S.SubFlexContainer>
                                    <S.Title>Billing Software Name :</S.Title>
                                    <S.Value>{storeData?.existing_software}</S.Value>
                                </S.SubFlexContainer>
                                <S.SubFlexContainer>
                                    <S.Title>Billing Software Count :</S.Title>
                                    <S.Value>{storeData?.no_of_existing_software}</S.Value>
                                </S.SubFlexContainer>
                                <S.SubFlexContainer>
                                    <S.Title>Billing Software Problem :</S.Title>
                                    <S.Value>{storeData?.existing_software_problem}</S.Value>
                                </S.SubFlexContainer>
                            </>
                        )}
                        <S.SubFlexContainer>
                            <S.Title>Any Online Software :</S.Title>
                            <Chip text={storeData?.is_online ? "Yes" : "No"} color={storeData?.is_online ? COLORS.Good : COLORS.Bad} />
                        </S.SubFlexContainer>
                        {storeData?.is_online && (
                            <S.SubFlexContainer>
                                <S.Title>Online Software Name :</S.Title>
                                <S.Value>{storeData?.online_software}</S.Value>
                            </S.SubFlexContainer>
                        )}
                    </S.StoreSubContainer>
                </S.StoreDataContainer>
            </div>

            {/* user detail */}
            <div>
                <HeadingContainer labels={["Added By"]} />
                <S.DataContainer>
                    <S.SubFlexContainer>
                        <S.Title>Name :</S.Title>
                        <S.Value link={true} onClick={() => handleNavigation("users", storeData.user_id)}>
                            {userData?.name}
                        </S.Value>
                    </S.SubFlexContainer>
                    <S.SubFlexContainer>
                        <S.Title>Title :</S.Title>
                        <S.Value>{userData?.title}</S.Value>
                    </S.SubFlexContainer>
                    <S.SubFlexContainer>
                        <S.Title>Area Codes :</S.Title>
                        <S.Value>{userData?.area_code.join(", ")}</S.Value>
                    </S.SubFlexContainer>
                    <S.SubFlexContainer>
                        <S.Title>Phone :</S.Title>
                        <S.Value>{userData?.phone}</S.Value>
                    </S.SubFlexContainer>
                    {userData?.email && (
                        <S.SubFlexContainer>
                            <S.Title>Email :</S.Title>
                            <S.Value>{userData?.email}</S.Value>
                        </S.SubFlexContainer>
                    )}
                </S.DataContainer>
            </div>

            {/* visits table */}
            <div>
                <HeadingContainer labels={["Store Visits"]} />
                <S.DataContainer>
                    <S.FilterContainer>
                        <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
                    </S.FilterContainer>
                    <VisitsTable data={visitsData} />
                </S.DataContainer>
            </div>
        </div>
    );
};

export default StoreDetails;
