import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    background-color: #ffde2c;
    border-radius: 18px;
    padding: 4px 6px;
    font-size: 20px;
    font-weight: 600;
    color: #253238;
    text-transform: uppercase;
    width: ${(props) => (props.full ? "100%" : props.ch ? props.ch : "10ch")};
    display: flex;
    align-items: center;
    justify-content: center;
`;
