import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCIqhuPcjwvdfKDrzeKTYkKRHGo5enP-1I",
    authDomain: "homedrop-in.firebaseapp.com",
    projectId: "homedrop-in",
    storageBucket: "homedrop-in.appspot.com",
    messagingSenderId: "18069281646",
    appId: "1:18069281646:web:763f93b343d92ba12abf7f",
    measurementId: "G-4ZSES5P70Q",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
