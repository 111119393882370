import styled from "@emotion/styled/macro";

export const TableHeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border-bottom: 1px solid #e2e8f0;
    background-color: #e2e8f0;
    padding: 3px 5px;
    margin-right: 8px;
    border-radius: 14px;
`;

export const TableHead = styled.div`
    flex: ${(props) => (props.flex ? props.flex : "1")};
    text-align: ${(props) => (props.$center ? "center" : "left")};
    margin-right: ${(props) => props.mr && props.mr};
`;

export const TableRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 6px 5px;
    cursor: pointer;
    border-radius: 14px;

    :hover {
        background-color: #f5f7f7;
    }
`;

export const Divider = styled.div`
    width: 98%;
    margin: 0 auto;
    height: 1px;
    border-bottom: 1px solid #e2e8f0;
`;

export const TableData = styled.div`
    flex: ${(props) => (props.flex ? props.flex : "1")};
    text-align: ${(props) => (props.$center ? "center" : "left")};
    margin-right: ${(props) => props.mr && props.mr};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #334155;
`;

export const TableBody = styled.div`
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;
