import React, { useEffect, useState } from "react";
import * as S from "./user-details.style";
import Spacer from "../../components/spacer/Spacer";
import { useParams } from "react-router-dom";
import { getUser } from "../../functions/user.functions";
import HeadingContainer from "../../components/heading-container/HeadingContainer";
import { formatTime } from "../../utils/format-date";
import { where } from "firebase/firestore";
import { MILLIS_IN_DAY } from "../../constants/constants";
import DateRange from "../../components/date-range/DateRange";
import StoreTable from "../../components/stores/store-table/StoreTable";
import VisitsTable from "../../components/visits/visits-table/VisitsTable";
import { getVisits } from "../../functions/visit.functions";
import { getStores } from "../../functions/store.functions";

const UserDetails = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const [storesData, setStoresData] = useState(null);
    const [visitsData, setVisitsData] = useState(null);
    const [activeHead, setActiveHead] = useState("Stores");
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [queries, setQueries] = useState({ user_id: where("user_id", "==", userId) });

    useEffect(() => {
        handleGetUserDetails();
    }, []);

    useEffect(() => {
        handleGetStoresData();
        handleGetVisitsData();
    }, [queries]);

    const handleGetUserDetails = async () => {
        let user = await getUser(userId);
        if (!user) {
            return;
        }
        setUserData(user);
        Promise.all([handleGetStoresData, handleGetVisitsData]).then((values) => {});
    };

    const handleGetVisitsData = async () => {
        let visits = await getVisits(queries);
        setVisitsData(visits);
    };

    const handleGetStoresData = async () => {
        let stores = await getStores(queries);
        setStoresData(stores);
    };

    const handleClickHead = (head) => {
        setActiveHead(head);
    };

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setQueries({ ...queries, start_date: where("date_created", ">=", start), end_date: where("date_created", "<", end + MILLIS_IN_DAY) });
        } else {
            setQueries({ ...queries, start_date: null, end_date: null });
        }
    };
    return (
        <div>
            <Spacer mt="110px" />
            <div>
                {/* user detail */}
                <div>
                    <HeadingContainer labels={["User Detail"]} />
                    <S.DataContainer>
                        <S.SubFlexContainer>
                            <S.Title>Name :</S.Title>
                            <S.Value>{userData?.name}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Title :</S.Title>
                            <S.Value>{userData?.title}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Area Codes :</S.Title>
                            <S.Value>{userData?.area_code.join(", ")}</S.Value>
                        </S.SubFlexContainer>
                        <S.SubFlexContainer>
                            <S.Title>Phone :</S.Title>
                            <S.Value>{userData?.phone}</S.Value>
                        </S.SubFlexContainer>
                        {userData?.email && (
                            <S.SubFlexContainer>
                                <S.Title>Email :</S.Title>
                                <S.Value>{userData?.email}</S.Value>
                            </S.SubFlexContainer>
                        )}
                        <S.SubFlexContainer>
                            <S.Title>Joined On :</S.Title>
                            <S.Value>{formatTime(userData?.date_created)}</S.Value>
                        </S.SubFlexContainer>
                    </S.DataContainer>
                </div>

                {/* store visit table */}
                <div>
                    <HeadingContainer labels={["Stores", "Visits"]} switchable={true} activeHead={activeHead} onClickHead={handleClickHead} />
                    <S.DataContainer>
                        <div>
                            <DateRange startDate={startDate} endDate={endDate} handleDateChange={handleDateChange} />
                        </div>
                        {activeHead === "Stores" && <StoreTable data={storesData} />}
                        {activeHead === "Visits" && <VisitsTable data={visitsData} />}
                    </S.DataContainer>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
