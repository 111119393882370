import React from "react";
import * as S from "./style";

const Header = ({ label, full = false, ch = null }) => {
    return (
        <S.Wrapper ch={ch} full={full}>
            {label}
        </S.Wrapper>
    );
};

export default Header;
