import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: 100%;
    display: ${(props) => (props.switchable ? "flex" : "grid")};
    ${(props) => props.switchable && "gap: 12px"};
    ${(props) => props.switchable && "align-items: center"};
    grid-template-columns: repeat(${(props) => props.count}, 1fr);
    background-color: #ffde2c;
    padding: 12px;
    border-radius: 36px;
`;

export const Head = styled.div`
    background-color: #253238;
    color: #fff;
    padding: 12px;
    border-radius: 30px;
    width: 15ch;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) => props.switchable && "cursor:pointer"};
    ${(props) => props.switchable && !props.activeHead && "opacity: 0.8"};
`;
export const Hint = styled.div`
    font-style: italic;
    color: #9e9e9e;
    font-size: 12px;
`;
